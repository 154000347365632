import Page from 'components/Page'
import { graphql, PageProps } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type DataProps = {
  sanityNotFoundPage: {
    page: Sanity.Page
  }
}

const NotFoundPage: FC<PageProps<DataProps>> = ({ data }) => {
  const { description, sections, title } = data.sanityNotFoundPage.page

  return (
    <Page
      description={description}
      sections={sections as Sanity.PageSections}
      title={title}
    />
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    sanityNotFoundPage {
      page {
        ...SanityPageData
      }
    }
  }
`
